<template>
  <div>
    <div class="bussinessContent">
      <el-checkbox-group
          :max="maxNumber?maxNumber:100"
          v-model="Conventional"
          size="mini"
          @change="change"
      >
        <el-checkbox-button

            v-for="city in showLine"
            :label="city.id"
            :key="city.id"
            size="small"
        >{{ city.name }}
        </el-checkbox-button>
      </el-checkbox-group>
    </div>
    <div class="clearfix">
      <el-button
          type="primary"
          size="small"
          class="fr"
          @click="saveLine"
          :loading="loading"
      >确认
      </el-button>
      <el-button
          type="text"
          size="small"
          class="fr mr15"
          @click="clickCancel"
      >取消
      </el-button>
    </div>
  </div>
</template>

<script>
let shippingLine=[
    {
        label_en:'会长单位',
        label_zh:'会长单位',
        id:5
    },
    {
        label_en:'副会长单位',
        label_zh:'副会长单位',
        id:4
    },
    {
        label_en:'监事单位',
        label_zh:'监事单位',
        id:3
    },{
        label_en:'理事单位',
        label_zh:'理事单位',
        id:2
    },{
        label_en:'会员单位',
        label_zh:'会员单位',
        id:1
    }
]




export default {
  name: "majorLine",
  props:['isSelect','number'],
  data() {
    return {
      showLine: [],
      Conventional: [],
      loading: false,
      disabled: false,
      maxNumber: 0
    }
  },
  mounted() {
    if (this.LOCALE === "en") {
      shippingLine.forEach(element => {
        element.name = element.label_en;
      });
    } else {
      shippingLine.forEach(element => {
        element.name = element.label_zh;
      });
    }
    this.showLine = shippingLine
    let haveLine = this.isSelect;
    if (haveLine) {
      this.Conventional = haveLine.map(item => {
        return item.id;
      });
    }
    this.maxNumber = this.number
    if (haveLine.length > this.number) {
      this.maxNumber = haveLine.length
    }
  },
  methods: {
    change(e) {
      if (e.length < this.number + 1) {
        this.maxNumber = this.number;
      } else {
        this.maxNumber = e.length;
      }
    },
    clickCancel() {
      this.$emit("cancelLine");
    },
    saveLine() {
        let arr =[]
      shippingLine.forEach(res=>{
          this.Conventional.forEach(ee=>{
              if(res.id == ee){
                  arr.push(res)
              }
          })
      })
      this.$emit("saveLine", arr);
    }
  }
}
</script>

<style scoped lang="less">
.mr15{
  margin-right: 15px;
}
</style>