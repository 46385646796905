<template>
  <div class="honestCompany">
    <div class="banner">
      <img
        src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/img_bg.png"
        alt=""
        srcset=""
      />
      <p class="bannerTxt">诚信服务公约签署单位名单</p>
    </div>
    <div class="content_area">
      <div class="searchContent">
        <div class="midSearch clearfix">
          <p class="fl">
            {{ $t("companyScreen") }}
          </p>
          <el-popover
            @show="showBusiness"
            placement="right-start"
            width="600"
            trigger="click"
            v-model="bussinessCheck"
          >
            <div v-if="bussinessCheck">
              <BussinessClassification
                @closeClass="bussinessCheck = false"
                @savaBusiness="savaBusiness"
                class="businessBox"
                :classification="classification"
                :isSelect="searchBussiness"
              ></BussinessClassification>
            </div>
            <el-button class="fl screenBtn" slot="reference" size="small">
              {{ $t("companyGoodBusiness") }}
            </el-button>
          </el-popover>
          <el-popover
            placement="right-start"
            width="600"
            trigger="click"
            v-model="majorShow"
          >
            <div>
              <newCompanyType
                v-if="majorShow"
                :number="5"
                @cancelLine="majorShow = false"
                @saveLine="saveLine"
                :isSelect="searchCompanyType"
              ></newCompanyType>
            </div>
            <el-button class="fl screenBtn" slot="reference" size="small"
              >单位类型
            </el-button>
          </el-popover>
          <el-popover
            placement="right-start"
            width="600"
            trigger="click"
            v-model="newLevelShow"
          >
            <div>
              <newLevel
                v-if="newLevelShow"
                :number="5"
                @cancelLine="newLevelShow = false"
                @saveLine="saveLevel"
                :isSelect="searchLevel"
              ></newLevel>
            </div>
            <el-button class="fl screenBtn" slot="reference" size="small"
              >信誉等级
            </el-button>
          </el-popover>
        </div>
        <div
          class="haveSelect clearfix"
          v-if="
            searchBussiness.length ||
            searchCompanyType.length ||
            searchLevel.length
          "
        >
          <p
            class="fl"
            style="max-width: 100px; text-align: right; line-height: 32px"
          >
            {{ $t("companyhaveSeletc") }} ：
          </p>
          <div class="fl" style="width: 1075px">
            <el-tag
              v-for="(tag, index) in searchBussiness"
              style="margin-right: 10px; margin-bottom: 10px"
              :key="index"
              disable-transitions
              closable
              @close="closetag(1, index)"
            >
              {{ tag.value_en | priorFormat(tag.value_zh, LOCALE) }}
            </el-tag>
            <el-tag
              type="success"
              v-for="(tag, index) in searchCompanyType"
              style="margin-right: 10px; margin-bottom: 10px"
              :key="tag.id * 10"
              disable-transitions
              closable
              @close="closetag(2, index)"
            >
              {{ tag.label_en | priorFormat(tag.label_zh, LOCALE) }}
            </el-tag>
            <el-tag
              type="warning"
              v-for="(tag, index) in searchLevel"
              style="margin-right: 10px; margin-bottom: 10px"
              :key="tag.id * 100"
              disable-transitions
              closable
              @close="closetag(3, index)"
            >
              {{ tag.label_en | priorFormat(tag.label_zh, LOCALE) }}
            </el-tag>
          </div>
        </div>
      </div>
      <div class="list">
        <div class="item" v-for="(item, index) in list" :key="index">
          <div class="topMsg">
            <el-image
              style="width: 72px; height: 72px"
              :src="item.logo || DEFAULT_LOGO"
              :fit="'scale-down'"
            ></el-image>
            <div class="info">
              <p class="companyName">
                <span>{{ item.name_zh || item.name_en || "-" }}</span>
                <img
                  v-if="item.company_integrity == 1"
                  src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/cheng.png"
                  alt=""
                  srcset=""
                />
              </p>
              <div class="infoBt">
                <span class="over"
                  >信息完善度:{{ item.completeness_catalogue || 0 }}%</span
                >
                <span class="level" v-if="item.company_credit"
                  >信用等级 <i v-if="item.company_credit == 1">AAA</i
                  ><i v-if="item.company_credit == 2">AA</i
                  ><i v-if="item.company_credit == 3">A</i></span
                >
              </div>
            </div>
            <el-popover
              placement="right"
              @show="showRwm(item.id)"
              width="300"
              trigger="click"
            >
              <div class="ewm" v-loading="loading">
                <el-image
                  style="width: 250px; height: 250px"
                  :src="ecode"
                  :fit="'cover'"
                ></el-image>

                <p>微信扫码去查看企业详细信息</p>
              </div>
              <el-button
                type="danger"
                slot="reference"
                size="small"
                class="looBtn"
                >查看详情</el-button
              >
            </el-popover>
          </div>
          <div class="btoMsg">
            <div class="botOne">
              <p class="title">
                总经理: <span>{{ item.contacts }}</span>
              </p>
              <p class="tel">
                联系电话:<span>{{ item.telephone }}</span>
              </p>
            </div>
            <div class="address">
              企业地址：<span>{{
                item.address_en | priorFormat(item.address_zh, LOCALE)
              }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="nodata" v-if="!list.length"  v-loading="loading">
        <noDataImg></noDataImg>
      </div>

      <div class="clearfix">
        <el-pagination
          class="fr"
          layout="total, prev, pager, next"
          :total="total"
          :current-page.sync="currentPage"
          :page-size="10"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import BussinessClassification from "@/baseComponents/newBussinessClassification";
import newCompanyType from "@/baseComponents/newCompanyType";
import newLevel from "@/baseComponents/newLevel";
export default {
  components: {
    BussinessClassification,
    newCompanyType,
    newLevel,
  },
  data() {
    return {
      classification: [],
      bussinessCheck: false,
      searchBussiness: [],
      majorShow: false,
      searchCompanyType: [],
      newLevelShow: false,
      searchLevel: [],
      list: [],
      total: 0,
      currentPage: 1,
      ecode: "",
      loading: false,
    };
  },
  watch: {
    currentPage() {
      this.getList()
    }
  },
  async mounted() {
    let data = await this.$store.dispatch("API_company/getBusinessList", {
      code: "ADVANTAGE_BUSINESS",
    });
    this.classification = data.data;
    this.getList();
  },
  methods: {
    async getList() {
      this.loading = true
      let parmas = {
        start: this.currentPage - 1,
        limit: 10,
        nopage: 0,
        company_integrity: 1,
        source: this.PJSource,
        user_id: this.USER_ID,
        my_company_id: this.USER_INFO.company_id,
        company_tag_zh_ids: this.searchBussiness
          .map((res) => {
            return res.id;
          })
          .toString(),
        genre_ids: this.searchCompanyType
          .map((res) => {
            return res.id;
          })
          .toString(),
      };
      if (this.searchLevel.length) {
        parmas.company_credit = this.searchLevel[0].id;
      }
      let data = await this.$store.dispatch(
        "API_company/getCompanyList",
        parmas
      );
      if (data.success) {
        this.list = data.data;
        this.total = data.total;
        this.loading = false
      }
    },
    async showBusiness() {
      this.bussinessCheck = true;
    },
    savaBusiness(e) {
      this.searchBussiness = e;
      this.bussinessCheck = false;
      this.getList();
    },
    saveLine(e) {
      console.log(e);
      this.searchCompanyType = e;
      this.majorShow = false;
      this.getList();
    },
    saveLevel(e) {
      this.searchLevel = e;
      console.log(e);
      this.newLevelShow = false;
      this.getList();
    },
    closetag(tag, index) {
      if (tag == 1) {
        this.searchBussiness.splice(index, 1);
      }
      if (tag == 2) {
        this.searchCompanyType.splice(index, 1);
      }
      if (tag == 3) {
        this.searchLevel.splice(index, 1);
      }
      this.getList();
    },
    async showRwm(id) {
      this.loading = true;
      let data = await this.$store.dispatch("API_company/getEcode", {
        page: "pages/sharePage/index",
        width: 300,
        scene: `7&${id}`,
        source: this.PJSource,
      });
      this.ecode = data.file;
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="less">
.content_area {
  padding: 30px 0;
}
.searchContent {
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  padding: 35px 25px;

  .midSearch {
    padding: 20px 0;

    p {
      line-height: 32px;
      font-size: 14px;
      padding-right: 10px;
    }
  }

  .screenBtn {
    margin-right: 15px;
  }
}
.banner {
  position: relative;
  img {
    width: 100%;
  }
  .bannerTxt {
    font-size: 32px;
    font-family: YouSheBiaoTiHei;
    // font-style: italic;
    font-weight: 900;
    white-space: nowrap;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
  }
}
.list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 0;
}
.item {
  margin: 10px 0;
  background: #fff;
  box-shadow: 0px 1px 7px 0px rgba(167, 167, 167, 0.19);
  padding: 16px;
  width: 588px;
}
.topMsg {
  display: flex;
  align-items: center;
  .info {
    width: 350px;
    margin-left: 15px;
    .companyName {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
    }
    .companyName {
      display: inline-block;
      font-size: 16px;
      font-weight: 900;
      width: 320px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .companyName img {
      margin-left: 10px;
    }
    .infoBt {
      display: flex;
      align-items: center;
      .over {
        font-size: 14px;
        display: inline-block;
        width: 150px;
      }
      .level {
        display: inline-block;
        padding: 5px 20px;
        font-size: 12px;
        background-color: rgb(254, 240, 240);
        color: #f56c6c;
        border-radius: 20px;
        // margin-left: 30px;
      }
    }
  }
}
.btoMsg {
  .botOne {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 20px 0;
    p {
      flex: 1;
      font-size: 14px;
      color: #999;
      span {
        color: #000;
      }
    }
  }
  .address {
    color: #999;
    span {
      color: #000;
    }
  }
}
.looBtn {
  margin-left: 40px;
}
.nodata {
  position: relative;
  min-height: 300px;
}
.ewm {
  text-align: center;
  padding: 10px 0;
  p {
    font-size: 15px;
  }
}
</style>