<template>
  <div>
    <div class="bussinessContent">
      <div v-for="(item, index) in showData" :key="index">
        <div class="bussinessListTitle">
          {{ item.value_en | priorFormat(item.value_zh, LOCALE) }}
        </div>
        <div class="btnList">
          <el-button
            v-for="(ii, dd) in item.children"
            :key="dd"
            size="mini"
            class="normal"
            :disabled="hasCheck.length>4 && !ii.act"
            :class="{ act: ii.act }"
            @click="clickBtn(ii,dd,index)"
          >
            {{ ii.value_en | priorFormat(ii.value_zh, LOCALE) }}</el-button
          >
        </div>
      </div>
    </div>
    <div class="clearfix">
      <el-button
        type="primary"
        size="small"
        class="fr"
        @click="saveLine"
        :loading="loading"
        >确认
      </el-button>
      <el-button type="text" size="small" class="fr mr15" style="margin-right:15px" @click="clickCancel"
        >取消
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["classification", "isSelect"],
  data() {
    return {
      showData: [],
      hasCheck: [],
      loading: false,
      disabled: false,
      maxNumber: 0,
    };
  },
  mounted() {
    this.showData = JSON.parse(JSON.stringify(this.classification)) ;
    console.log(this.isSelect)
    if(this.isSelect.length){
      this.hasCheck = this.isSelect
      this.showData.forEach(ee=>{
        ee.children.forEach(eee=>{
          this.isSelect.forEach(eeee=>{
            if(eeee.id == eee.id){
              eee.act = true
            }
          })
        })
      })
    }
  },
  methods: {
      clickBtn(ii,dd,index){
        let curry= this.showData[index].children[dd]
        if(this.showData[index].children[dd].act){
          this.$set(curry,'act',false)
          this.hasCheck = this.hasCheck.filter(res=>{
            return res.id != ii.id
          })
        }else{
           this.$set(curry,'act',true)
           this.hasCheck.push(ii)
        }
         console.log(this.hasCheck)
      },
      saveLine(){
        this.$emit('savaBusiness',this.hasCheck)
      },
      clickCancel(){
        this.showData.forEach(res=>{
          res.children.forEach(ee=>{
            ee.act=false
          })
        })
        this.hasCheck=[]
         this.$emit('closeClass')
      }
  },
};
</script>

<style scoped lang="less">
.bussinessListTitle {
  line-height: 26px;
  border-bottom: 1px solid #f2f2f2;
}
.bussinessContent,
.btnList {
  padding: 15px 0 5px;
}
.normal {
  background: #f3f3f3;
  margin-right: 10px;
  margin-bottom: 10px;
}
.el-button + .el-button {
  margin-left: 0;
}
.act {
  color: #fff;
  background-color: #f56c6c;
  border-color: #f56c6c;
  box-shadow: -1px 0 0 0 #f9a7a7;
}
</style>